/* 2.03 - Template Gapping or Spacing - Start
================================================== */
.section_space {
	padding-top: 120px;
	padding-bottom: 65px;
}

.section_space_ProcessTechnology {
	padding-top: 10px;
}
/* 2.03 - Template Gapping or Spacing - End
================================================== */