/* 3.03 - Page Header, Page Banner, Breadcrumb - Start
================================================== */
.page_banner_section {
	z-index: 1;
	position: relative;
	padding: 65px 0 130px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	margin-top: 99px;
	.heading_focus_text {
		margin-bottom: 24px;
	}
}

.page_banner_section_pesquisa {
	background-color: #096322;
	// background-color: #4ca64c;
	// background-color: #a0e6b8;
	// background-color: #0a6b25;
}

.page_banner_section_desccargo {
	// background-color: #009ef7;
	// background-color: #334ea1;
	background-color: #223365;
	// background-color: #e2effa;
}

.page_banner_nosso_proposito{
	background-color: var(--bs-dark);
	padding: 30px 0 130px;
}

.page_title {
	font-size: 55px;
	line-height: 70px;
}
/* 3.03 - Page Header, Page Banner, Breadcrumb - End
================================================== */