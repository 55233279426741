/* 2.17 - Iconbox - Start
================================================== */
.iconbox_block {
	padding: 50px 50px 42px;
	transition: var(--bs-transition);
	background-color: var(--bs-white);
	border-radius: var(--bs-border-radius-sm);
	box-shadow: 0 1px 2px 0 rgba(174, 191, 210, 0.30);
	min-height: 500px;
	&:hover {
		transform: translateY(-2px);
		box-shadow: 0 20px 30px 0 rgba(174, 191, 210, 0.30);
	}
	.iconbox_icon {
		width: 80px;
		height: 80px;
		flex: 0 0 auto;
		margin: 0 0 32px;
		border-radius: 100%;
		align-items: center;
		display: inline-flex;
		justify-content: center;
		color: var(--bs-primary);
		background-color: var(--bs-primary-bg-subtle);
		img {
			max-width: 40px;
		}
	}
	.iconbox_title {
		font-size: 30px;
		font-weight: 600;
		line-height: 36px;
		margin-bottom: 20px;
		font-family: 'Axiforma SemiBold';
	}
	p {
		font-size: 16px;
	}
}

// Layout Icon Left
.iconbox_block.layout_icon_left {
	padding: 30px;
	display: inline-flex;
	align-items: flex-start;
	border-radius: var(--bs-border-radius);
	.iconbox_icon {
		width: 70px;
		height: 70px;
		margin: 0 30px 0 0;
		border-radius: 10px;
		img {
			max-width: 32px;
		}
	}
	.iconbox_title {
		font-size: 20px;
		line-height: 28px;
		margin-bottom: 12px;
	}
}

// Mega Manu Item
.iconbox_block_2 {
	display: block;
	color: var(--bs-dark);
	padding: 15px 25px 10px 15px;
	transition: var(--bs-transition);
	border-radius: var(--bs-border-radius);
	.icon_title_wrap {
		gap: 10px;
		display: flex;
		margin-bottom: 10px;
		align-items: flex-start;
	}
	.iconbox_icon {
		width: 30px;
		height: 30px;
		flex: 0 0 auto;
		border-radius: 5px;
		align-items: center;
		display: inline-flex;
		justify-content: center;
		background-color: rgba(243, 111, 69, 0.1);
		img {
			max-width: 16px;
		}
	}
	.iconbox_title {
		line-height: 1;
		margin: 6px 0 0;
		font-size: 20px;
		font-weight: 600;
		font-family: 'Axiforma SemiBold';
	}
	.badge {
		margin-top: 5px;
	}
	.description {
		font-size: 15px;
		color: var(--bs-body-color);
	}
	&:hover {
		background-color: var(--bs-light);
	}
}
/* 2.17 - Iconbox - End
================================================== */